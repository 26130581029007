/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Typography, Modal, notification, Button, Form, Select, Col, Row, Input, Menu, Layout, Tooltip, Tabs, List, Table, Spin, Drawer, Checkbox } from 'antd';
import { ReactGrid, Column, DropPosition } from "@silevis/reactgrid";
import "@silevis/reactgrid/styles.css";
import * as XLSX from 'xlsx';
const { Title, Text } = Typography;
import Invoice from './Invoice';
import MonthlyRecordGrid from './MonthlyRecordGrid';
import RechargeSheet from './RechargeSheet';
import { connect } from 'react-redux';
import { get } from 'lodash';
import moment from 'moment';
import {
    FileExcelOutlined,
    SettingOutlined,
    EditOutlined,
    DeleteOutlined,
    FullscreenOutlined,
    FilePdfOutlined,
    MailOutlined,
    ReloadOutlined
} from '@ant-design/icons';

import { fetchInvoiceDashBoardList, updateDashboardInvoice, fetchBillingDashBoardList, fetchInitialBillingDashBoardList, updateDashboardBilling, fetchBillingDashBoardPdfUrl, fetchInvoiceDashBoardPdfUrl, sendEmailForSignatureInvoice, fetchInvoiceMonthList, fetchBillingMonthList, fetchRechargeMonthList, generateExcelForRecharge } from '../redux/billing/action'

const { Option } = Select;
const { TabPane } = Tabs;
const { Sider } = Layout;

const InvoiceAndBilling = (props) => {
    const { selectedProject, onUpdateDashboardInvoice, onFetchInvoiceDashBoardList, invoiceRowsList, isInvoiceDataLoading,
        //
        onFetchBillingDashBoardList, onFetchInitialBillingDashBoardList, onUpdateDashboardBilling, onFetchBillingDashBoardPdfUrl, onFetchInvoiceDashBoardPdfUrl, onFetchInvoiceMonthList, onFetchBillingMonthList, onFetchRechargeMonthList, onSendEmailForSignatureInvoice, onGenerateExcelForRecharge, billingDashboardList, initialBillingDashboardList, invoiceMonthsList, billingMonthsList, rechargeMonthsList, sowDetails, isMonthsListLoading, isBillingDataLoading, isInitialBillingDataLoading
    } = props;

    console.log("sowDetails", sowDetails)
    const [invoiceTitle, setInvoiceTitle] = useState('');
    const [invoiceModalVisible, setInvoiceModalVisible] = useState(false);
    const [billingTitle, setBillingTitle] = useState('');
    const [billingModalVisible, setBillingModalVisible] = useState(false);
    const [RechargeTitle, setRechargeTitle] = useState('');
    const [rechargeModalVisible, setRechargeModalVisible] = useState(false);
    //
    const goingYear = new Date().getFullYear();
    const today = new Date();
    const CurrentMonth = today.toLocaleString('default', { month: 'long' })
    //
    const [clickedInterval, setClickedInterval] = useState(`${CurrentMonth},${goingYear}`);
    const [invoice, setInvoice] = useState(true);
    const [addMonthModalVisible, setAddMonthModalVisible] = useState(false);
    const [monthName, setMonthName] = useState(CurrentMonth);
    const [timesheetData, setTimesheetData] = useState([]);
    const [data, setData] = useState(billingDashboardList.data);
    const [initialData, setInitialData] = useState(billingDashboardList.data);
    const [rechargeData, setRechargeData] = useState([]);
    const [selectedMonthAndYear, setSelectedMonthAndYear] = useState('');
    const [selectedMonth, setSelectedMonth] = useState('');
    const [selectedYear, setSelectedYear] = useState(goingYear);
    const [billingPipelines, setBillingPipelines] = useState(initialData?.pipelineDetails ? initialData.pipelineDetails.map(p => p.pipelineName) : []);
    const [pipelines, setPipelines] = useState(sowDetails.length ? sowDetails.map(p => p.pipelineSection) : []);
    // const [pipelines, setPipelines] = useState(initialData?.pipelineDetails ? initialData.pipelineDetails.map(p => p.pipelineName) : []);
    const [selectedPipeline, setSelectedPipeline] = useState(pipelines.length > 0 ? pipelines[0] : '');
    const [billingPdfUrl, setBillingPdfUrl] = useState('');
    const [invoicePdfUrl, setInvoicePdfUrl] = useState('');
    const [rechargeExcelUrl, setRechargeExcelUrl] = useState('');
    const [pdfModalVisible, setPdfModalVisible] = useState(false)
    const [ratesModalVisible, setRatesModalVisible] = useState(false)
    const [emailModalVisible, setEmailModalVisible] = useState(false);
    const [invoicePdfList, setInvoicePdfList] = useState([]);
    const [billingPdfList, setBillingPdfList] = useState([]);
    const [toEmail, setToEmail] = useState('');
    const [reportKey, setReportKey] = useState('');
    const [projectInvoiceId, setProjectInvoiceId] = useState('');
    const [reportVersion, setReportVersion] = useState('')
    const [billingColumnList, setBillingColumnList] = useState([
        {
            columnId: `${selectedPipeline}-workingCount`,
            name: "workingCount"
        },
        {
            columnId: `${selectedPipeline}-standByCount`,
            name: 'standByCount'
        },
        {
            columnId: `${selectedPipeline}-mobilizedCount`,
            name: 'mobilizedCount'
        },
        {
            columnId: `${selectedPipeline}-demobilizedCount`,
            name: 'demobilizedCount'
        },
        {
            columnId: `${selectedPipeline}-addColOne`,
            name: 'In Transit'
        },
        {
            columnId: `${selectedPipeline}-addColTwo`,
            name: 'Comments'
        },
    ])
    console.log("first")
    const [isColumnsModalVisible, setIsColumnsModalVisible] = useState(false)
    const [selectedColumns, setSelectedColumns] = useState(billingColumnList.map(column => column.columnId));
    const [visibleColumn, setVisibleColumn] = useState([]);
    const [filteredKeys, setFilteredKeys] = useState([]);
    //
    const [isRechargeModalVisible, setIsRechargeModalVisible] = useState(false)

    const [editColumnForm] = Form.useForm();
    let uuid = self.crypto.randomUUID();
    const [year, setYear] = useState(goingYear);
    const monthOrder = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
    ];

    const monthsWithIndex = {
        "January": 1,
        "February": 2,
        "March": 3,
        "April": 4,
        "May": 5,
        "June": 6,
        "July": 7,
        "August": 8,
        "September": 9,
        "October": 10,
        "November": 11,
        "December": 12
    }

    const [menuData, setMenuData] = useState([{
        "id": 1,
        "name": CurrentMonth,
        "year": year,
    }])

    const [monthList, setMonthList] = useState([
        { "id": 1, "name": "January" },
        { "id": 2, "name": "February" },
        { "id": 3, "name": "March" },
        { "id": 4, "name": "April" },
        { "id": 5, "name": "May" },
        { "id": 6, "name": "June" },
        { "id": 7, "name": "July" },
        { "id": 8, "name": "August" },
        { "id": 9, "name": "September" },
        { "id": 10, "name": "October" },
        { "id": 11, "name": "November" },
        { "id": 12, "name": "December" }
    ]);

    const years = [];
    for (let i = 0; i < 10; i++) {
        years.push((goingYear - i).toString());
    }

    const rechargeExcelHeaders = ["wk", "Date", "Name", "Role", "Employer", "WBS/ WBS/ COST CENTRE", "Activity Type", "Charge Rate", "Daily Conv", "Recharge", "ID", "Employee or Contractor", "Sender Cost Centre"]

    const AddMonth = () => {
        if (selectedMonth && selectedYear) {
            const newData = [...menuData, { name: selectedMonth, year: selectedYear }];
            setMenuData(newData);
            setAddMonthModalVisible(false);
        }
        setSelectedMonth('')
        editColumnForm.resetFields();
    };

    //set selected columns for billing
    const generateColumns = (data, selectedPipeline) => {
        const columns = [
            { columnId: "dsrDate", width: 150, colspan: 2 },
            { columnId: "dayName", width: 100 },
            { columnId: "mobCount", width: 50, colspan: 2 },
            { columnId: "mobPersonnel", width: 250 },
            { columnId: "demobCount", width: 50, colspan: 2 },
            { columnId: "demobPersonnel", width: 250 },
        ];

        const pipeline = data?.pipelineDetails?.find(p => p.pipelineName === selectedPipeline);

        if (pipeline) {
            pipeline.personnelData.forEach(role => {
                columns.push({ columnId: `${pipeline.pipelineName}-${role.roleName}Count`, width: 50, colspan: 2 });
                columns.push({ columnId: `${pipeline.pipelineName}-${role.roleName}Personnel`, width: 250 });
            });

            columns.push({ columnId: `${pipeline.pipelineName}-mobilizedCount`, width: 100 });
            columns.push({ columnId: `${pipeline.pipelineName}-demobilizedCount`, width: 100 });
            columns.push({ columnId: `${pipeline.pipelineName}-workingCount`, width: 100 });
            columns.push({ columnId: `${pipeline.pipelineName}-standByCount`, width: 100 });
            columns.push({ columnId: `${pipeline.pipelineName}-addColOne`, width: 100 });
            columns.push({ columnId: `${pipeline.pipelineName}-addColTwo`, width: 100 });
        }

        return columns;
    };
    useEffect(() => {
        if (selectedPipeline) {
            const data = [
                {
                    columnId: `${selectedPipeline}-mobilizedCount`,
                    name: 'Mobilized'
                },
                {
                    columnId: `${selectedPipeline}-demobilizedCount`,
                    name: 'Demobilized'
                },
                {
                    columnId: `${selectedPipeline}-workingCount`,
                    name: "Working"
                },
                {
                    columnId: `${selectedPipeline}-standByCount`,
                    name: 'StandBy'
                },
                {
                    columnId: `${selectedPipeline}-addColOne`,
                    name: 'In Transit'
                },
                {
                    columnId: `${selectedPipeline}-addColTwo`,
                    name: 'Comments'
                },
            ]
            const allBillingColumns = generateColumns(initialData, selectedPipeline)
            setVisibleColumn(allBillingColumns.map(item => item.columnId))
            setBillingColumnList(data)
            setSelectedColumns(data.map(column => column.columnId))
        }
    }, [selectedPipeline])
    //for getting months list
    useEffect(() => {
        onFetchInvoiceMonthList({ "projectId": selectedProject.id })
        onFetchBillingMonthList({ "projectId": selectedProject.id })
        onFetchRechargeMonthList({ "projectId": selectedProject.id })
    }, [selectedProject, invoice])

    useEffect(() => {
        const currentMonth = new Date().getMonth();
        const currentYear = new Date().getFullYear();
        const currentMonthAndYearStr = `${String(currentMonth + 1).padStart(2, '0')}-${currentYear}`;
        if (invoice == 1 && (invoiceMonthsList && invoiceMonthsList.length)) {
            const newMenuData = invoiceMonthsList.map((item, index) => {
                const [month, year] = item.invoiceMonthAndYear.split('-');
                return {
                    id: index + 1,
                    name: monthOrder[parseInt(month) - 1],
                    year: parseInt(year)
                };
            });
            if (invoiceMonthsList.some(item => item.invoiceMonthAndYear === currentMonthAndYearStr)) {
                setMenuData(newMenuData);
            } else {
                setMenuData([{ "id": 1, "name": CurrentMonth, "year": currentYear, }, ...newMenuData]);
            }
        } else if (invoice == 2 && (billingMonthsList && billingMonthsList.length)) {
            const newMenuData = billingMonthsList.map((item, index) => {
                const [month, year] = item.billingMonthAndYear.split('-');
                return {
                    id: index + 1,
                    name: monthOrder[parseInt(month) - 1],
                    year: parseInt(year)
                };
            });
            if (billingMonthsList.some(item => item.billingMonthAndYear === currentMonthAndYearStr)) {
                setMenuData(newMenuData);
            } else {
                setMenuData([{ "id": 1, "name": CurrentMonth, "year": currentYear, }, ...newMenuData]);
            }
        } else if (invoice == 3 && (rechargeMonthsList && rechargeMonthsList.length)) {
            const newMenuData = rechargeMonthsList.map((item, index) => {
                const [month, year] = item.monthAndYear.split('-');
                return {
                    id: index + 1,
                    name: monthOrder[parseInt(month) - 1],
                    year: parseInt(year)
                };
            });
            if (rechargeMonthsList.some(item => item.monthAndYear === currentMonthAndYearStr)) {
                setMenuData(newMenuData);
            } else {
                setMenuData([{ "id": 1, "name": CurrentMonth, "year": currentYear, }, ...newMenuData]);
            }
        } else {
            setMenuData([{
                "id": 1,
                "name": CurrentMonth,
                "year": currentYear,
            }]);
        }
        setMonthName(CurrentMonth);
        setYear(goingYear)
        setClickedInterval(`${CurrentMonth},${goingYear}`)
    }, [invoiceMonthsList, billingMonthsList, invoice]);

    //for Billing
    useEffect(() => {
        setData(billingDashboardList.data?.mobDemobDetails?.length ? billingDashboardList.data : initialBillingDashboardList)
        setInitialData(billingDashboardList.data?.mobDemobDetails?.length ? billingDashboardList.data : initialBillingDashboardList)
        if (billingDashboardList.reportDetails) {
            setBillingPdfUrl(billingDashboardList.reportDetails.fileUrl)
            setBillingPdfList([billingDashboardList.reportDetails])
        }
    }, [billingDashboardList, initialBillingDashboardList])

    //for Invoices
    useEffect(() => {
        if (invoiceRowsList && invoiceRowsList.reportDetails?.length) {
            setInvoicePdfUrl(invoiceRowsList.reportDetails.fileUrl)
            setInvoicePdfList(invoiceRowsList.reportDetails)
            setProjectInvoiceId(invoiceRowsList.id)
        }
    }, [invoiceRowsList])

    useEffect(() => {
        if (pipelines.length > 0 && !selectedPipeline) {
            setSelectedPipeline(pipelines[0]);
        }
    }, [pipelines, selectedPipeline]);

    useEffect(() => {
        if (initialData && initialData.pipelineDetails && initialData.pipelineDetails.length) {
            setBillingPipelines(initialData.pipelineDetails.map(p => p.pipelineName));
            // setPipelines(initialData.pipelineDetails.map(p => p.pipelineName));
            setPipelines(sowDetails.length ? sowDetails.map(p => p.pipelineSection) : []);
        }
    }, [initialData]);


    useEffect(() => {
        const monthIndex = monthOrder.indexOf(monthName) + 1;
        const formattedMonth = monthIndex < 10 ? `0${monthIndex}` : monthIndex;
        const invoiceMonthAndYear = `${formattedMonth}-${year}`
        setSelectedMonthAndYear(`${formattedMonth}-${year}`);
        //invoices
        onFetchInvoiceDashBoardList({ projectId: selectedProject.id, invoiceMonthAndYear: invoiceMonthAndYear })
        //billing
        onFetchBillingDashBoardList({ projectId: selectedProject.id, billingMonthAndYear: invoiceMonthAndYear })
        onFetchInitialBillingDashBoardList({ projectId: selectedProject.id, monthAndYear: invoiceMonthAndYear })
        setPipelines([])
        setSelectedPipeline('')
        setBillingPdfUrl('')
    }, [selectedProject, monthName])


    const handleMonthModalCancel = () => {
        // editColumnForm.resetFields();
        setAddMonthModalVisible(false);
        setSelectedMonth('')
    }

    const handleInterval = async (value) => {
        const [month, year] = value.key.split(',');
        setMonthName(month)
        setYear(year)
        setClickedInterval(value.key)
        // Set the selected month and year in the format "MM-YYYY"
        const monthIndex = monthOrder.indexOf(month) + 1;
        const formattedMonth = monthIndex < 10 ? `0${monthIndex}` : monthIndex;
        setSelectedMonthAndYear(`${formattedMonth}-${year}`);
    }

    //Invoice
    const showInvoiceModal = () => {
        if (selectedProject) {
            setInvoiceTitle("Update Invoice");
            setInvoiceModalVisible(true);
        } else {
            notification.warn({ message: 'Select project to add new invoice.' });
        }
    };

    //Recharge Expand
    const showRechargeModal = () => {
        if (selectedProject) {
            setRechargeTitle("Recharge");
            setRechargeModalVisible(true);
        } else {
            notification.warn({ message: 'Select project to add new recharge.' });
        }
    };

    //Billing
    const showBillingModal = () => {
        if (selectedProject) {
            setBillingTitle("Update Monthly Record");
            setBillingModalVisible(true);
        } else {
            notification.warn({ message: 'Select project to add new billing.' });
        }
    };

    const handleTabChange = (key) => {
        setInvoice(key);
    };

    function calculateValues(data) {
        data.accumulativeTotalValue = (data.accumulativeQty * data.rate).toFixed(2);
        data.inMonthInvoice = (data.inMonthQty * data.rate).toFixed(2);
        return data;
    }

    const handleAddRow = () => {
        if (!selectedProject) {
            notification.warn({ message: 'Select Project to proceed.' });
            return;
        }
        const newRow = {
            uniqueId: uuid,
            category: "",
            description: "",
            milestoneReference: "",
            accumulativeQty: 0,
            inMonthQty: 0,
            unit: "",
            rate: 0,
            accumulativeTotalValue: 0,
            inMonthInvoice: 0,
            isOpen: false,
        };
        setTimesheetData(prevData => [...prevData, calculateValues(newRow)]);
    };

    const handleAddRechargeRow = () => {
        if (selectedProject) {
            setIsRechargeModalVisible(true)
            setRechargeTitle('Add Recharge')
        } else {
            notification.warn({ message: 'Select Project to proceed.' });
        }
    };
    //invoices
    const updateInvoiceData = () => {
        if (!selectedProject) {
            notification.warn({ message: 'Select Project to proceed.' });
            return;
        }
        if (timesheetData.length) {
            const finalData = timesheetData.filter(item => item.category && item.description)
            const obj = {
                "projectId": selectedProject.id,
                "invoiceMonthAndYear": selectedMonthAndYear,
                "data": finalData
            }
            onUpdateDashboardInvoice(obj);
        }
    }
    //billing
    const updateBillingData = () => {
        if (!selectedProject) {
            notification.warn({ message: 'Select Project to proceed.' });
            return;
        }
        if (data) {
            const obj = {
                "projectId": selectedProject.id,
                "billingMonthAndYear": selectedMonthAndYear,
                "data": data
            }
            onUpdateDashboardBilling(obj);
        }
    }

    //generateBillingPdf
    const generateBillingPdf = () => {
        if (!selectedProject) {
            notification.warn({ message: 'Select Project to proceed.' });
            return;
        }
        if (data) {
            const config = { equipmentColumnsDisabled: (filteredKeys && filteredKeys.length) ? filteredKeys.map(item => item.replace(`${selectedPipeline}-`, "")) : [] };
            const obj = {
                "projectId": selectedProject.id,
                "billingMonthAndYear": selectedMonthAndYear,
                "data": { "billingData": data, config: config }
            }
            onFetchBillingDashBoardPdfUrl(obj);
        }
    }

    //generateBillingPdf
    const generateRechargeExcel = () => {
        if (!selectedProject) {
            notification.warn({ message: 'Select Project to proceed.' });
            return;
        }
        if (data) {
            const obj = {
                "projectId": selectedProject.id,
                "monthAndYear": selectedMonthAndYear,
            }
            onGenerateExcelForRecharge(obj);
        }
    }

    //generateInvoicePdf
    const generateInvoicePdf = () => {
        if (!selectedProject) {
            notification.warn({ message: 'Select Project to proceed.' });
            return;
        }
        const obj = {
            "projectId": selectedProject.id,
            "invoiceMonthAndYear": selectedMonthAndYear,
        }
        onFetchInvoiceDashBoardPdfUrl(obj);
    }

    //
    const openPdfModal = () => {
        if (!selectedProject) {
            notification.warn({ message: 'Select Project to proceed.' });
            return;
        }
        setPdfModalVisible(true);
    }

    const pdfModalCancel = () => {
        setPdfModalVisible(false);
    }

    // Key mappings to match headers with object keys
    const headerKeyMap = {
        "wk": "rechargeDate",
        "Date": "rechargeDate",
        "Name": "name",
        "Role": "role",
        "Employer": "employer",
        "WBS/ WBS/ COST CENTRE": "wbsCostCenter",
        "Activity Type": "activityType",
        "Charge Rate": "chargeRate",
        "Daily Conv": "dailyConversion",
        "Recharge": "recharge",
        "ID": "employeeId",
        "Employee or Contractor": "employeeType",
        "Sender Cost Centre": "senderCostCenter",
    };

    const getMonthWeekNumber = (date) => {
        const currentDate = new Date(date);
        const dayOfMonth = currentDate.getDate();
        const weekNumber = Math.ceil(dayOfMonth / 7);
        return weekNumber;
    };

    // Function to handle Excel download
    const handleDownloadExcel = () => {
        if (!selectedProject) {
            notification.warn({ message: 'Select Project to proceed.' });
            return;
        }
        const workbook = XLSX.utils.book_new();

        // Prepare worksheet data with headers and rows
        const worksheetData = [rechargeExcelHeaders];
        rechargeData.forEach(dataRow => {
            const row = rechargeExcelHeaders.map(header => {
                if (header === "wk") {
                    return getMonthWeekNumber(dataRow[headerKeyMap[header]]);
                } else if (header === "Date") {
                    return moment(dataRow[headerKeyMap[header]]).format("DD-MM-YYYY");
                } else {
                    return dataRow[headerKeyMap[header]];
                }
            });
            worksheetData.push(row);
        });

        // Create a worksheet
        const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

        // Define column widths
        worksheet['!cols'] = [
            { wch: 5 },  // wk
            { wch: 10 }, // Date
            { wch: 20 }, // Name
            { wch: 15 }, // Role
            { wch: 15 }, // Employer
            { wch: 20 }, // WBS/ WBS/ COST CENTRE
            { wch: 10 }, // Activity Type
            { wch: 10 }, // Charge Rate
            { wch: 10 }, // Daily Conv
            { wch: 10 }, // Recharge
            { wch: 15 }, // ID
            { wch: 20 }, // Employee or Contractor
            { wch: 20 }, // Sender Cost Centre
        ];

        // Add the worksheet to the workbook
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Whale');

        // Write the workbook to a binary string
        const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });

        // Convert the binary string to a blob
        const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });

        // Trigger the download
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'WhaleData.xlsx';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
    };

    // Helper function to convert string to ArrayBuffer
    const s2ab = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i < s.length; i++) {
            view[i] = s.charCodeAt(i) & 0xFF;
        }
        return buf;
    };

    //rates modal open 
    const handelRatesModalOpen = () => {
        if (selectedProject) {
            setRatesModalVisible(true)
        } else {
            notification.warn({ message: 'Select Project to proceed.' });
            return;
        }
    }

    // Define columns for the table
    const columns = [
        {
            title: 'Serial No',
            dataIndex: 'serialNo',
            key: 'serialNo',
        },
        {
            title: 'Version',
            dataIndex: 'version',
            key: 'version',
            align: 'center'
        },
        {
            title: 'Original Document',
            dataIndex: 'originalDocuments',
            key: 'originalDocuments',
            align: 'center',
            render: (originalDocuments) => (
                originalDocuments ?
                    <Tooltip title="View / Download Report">
                        <span className="icon-24 mr-2 cursor-pointer edit-hover">
                            <Button
                                href={originalDocuments}
                                className='p-0 pdfIcon border-0'
                                size='small'
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <FilePdfOutlined className='pdfIconRed' />
                            </Button>
                        </span>
                    </Tooltip>
                    : 'Not available'
            )

        },
        {
            title: 'Signed Document',
            dataIndex: 'signedDocuments',
            key: 'signedDocuments',
            align: 'center',
            render: (signedDocuments) => (
                signedDocuments ?
                    <Tooltip title="View / Download Report">
                        <span className="icon-24 mr-2 cursor-pointer edit-hover">
                            <Button
                                href={signedDocuments}
                                className='p-0 pdfIcon border-0'
                                size='small'
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <FilePdfOutlined className='pdfIconRed' />
                            </Button>
                        </span>
                    </Tooltip>
                    : 'Not available'
            ),
        },
        {
            title: 'Action',
            dataIndex: 'signedDocuments',
            key: 'signedDocuments',
            align: 'center',
            render: (signedDocuments, record) => (
                <Tooltip title="Request for signature">
                    <span className={`icon-18 mr-2 ${signedDocuments ? '' : 'cursor-pointer edit-hover'}`}>
                        {/* <MailOutlined
                            style={{ fontSize: '18px', color: 'gray' }}
                        onClick={() => { if (!signedDocuments && invoice == 1) handleAction(record) }}
                        /> */}
                        <MailOutlined
                            style={{ fontSize: '18px', color: signedDocuments ? 'gray' : 'inherit' }}
                            onClick={() => { if (!signedDocuments && invoice == 1) handleAction(record) }}
                        />
                    </span>
                </Tooltip>
            ),
        },
    ];

    // Transform data to match Invoice download table structure
    const docuData = invoicePdfList.map((report, index) => ({
        key: report.orginal.s3Obj.key,
        version: report.version,
        serialNo: index + 1,
        originalDocuments: report.orginal?.s3Obj.fileUrl,
        signedDocuments: report?.signed?.s3Obj?.fileUrl,
    }));

    // Transform data to match Billing download table structure
    const docsBillingData = billingPdfList.map((report, index) => ({
        key: '1',
        version: '1',
        serialNo: index + 1,
        originalDocuments: report.fileUrl,
        signedDocuments: '',
    }));


    // Action handler
    const handleAction = (record) => {
        setReportVersion(record.version)
        setEmailModalVisible(true);
    };
    const loggedInUser = localStorage.getItem('user');
    const userDetails = JSON.parse(loggedInUser)
    const handleEmailForRequest = () => {
        if (toEmail) {
            const obj = {
                "projectId": selectedProject.id,
                "invoiceMonthAndYear": selectedMonthAndYear,
                "projectInvoiceId": projectInvoiceId.toString(),
                "reportVersion": reportVersion,
                "signerEmail": toEmail.toLowerCase(),
                "signerName": toEmail,
                "requestedByEmail": userDetails.email,
                "requestedByName": userDetails.name,
            }
            onSendEmailForSignatureInvoice(obj)
            setTimeout(handleCancelEmailModal, 1000)
        }
    }

    const handleCancelEmailModal = () => {
        setEmailModalVisible(false);
        setReportKey('')
        setToEmail('')
    }

    const truncateTableData = (data) => {
        return < Tooltip placement="topLeft" title={data} >{data}</Tooltip >
    }

    const handleCheckboxChange = (columnId) => {
        setSelectedColumns(prevSelectedColumns => {
            if (prevSelectedColumns.includes(columnId)) {
                return prevSelectedColumns.filter(id => id !== columnId);
            } else {
                return [...prevSelectedColumns, columnId];
            }
        });
    };

    return (
        <div>
            <div className="flex-auto">
                <div className="d-flex align-items-center tab-content-title">
                    <Tabs className='billing-tabs' defaultActiveKey="1" onChange={handleTabChange}>
                        <TabPane className='billing-tabpane' tab="Invoice" key="1" />
                        <TabPane className='billing-tabpane' tab="Billing" key="2" />
                        <TabPane className='billing-tabpane' tab="Recharge" key="3" />
                    </Tabs>
                    {invoice == 2 && <div className='billing-select'>
                        <Select
                            className='pipeline-select'
                            placeholder='Select Pipeline'
                            onChange={(value) => setSelectedPipeline(value)}
                            value={selectedPipeline}
                            allowClear
                            showSearch
                            filterOption={(input, option) =>
                                (option.children).toLowerCase().includes(input.toLowerCase())
                            }
                        >
                            {pipelines && pipelines.map((item, i) => (
                                <Option key={i} value={item}>
                                    {item}
                                </Option>
                            ))}
                        </Select>
                    </div>}

                    <div className="expand-billing-sheet">
                        {invoice == 1 ?
                            <>
                                <Text onClick={updateInvoiceData} className="ps-3 add-new-team">
                                    <span className="icon-22">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" width="24px" height="24px">
                                            <path d="M19 21H5c-1.1 0-2-.9-2-2V5c0-1.1.9-2 2-2h11l5 5v11c0 1.1-.9 2-2 2zm-7-4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm3-11H6v2h9V6z" />
                                        </svg>
                                    </span>
                                    Save
                                </Text>
                                <Text
                                    onClick={generateInvoicePdf}
                                    className="ps-3 add-new-team"
                                >
                                    <span className="icon-22">
                                        <FilePdfOutlined />
                                    </span>
                                    Generate Report
                                </Text>
                                {(invoiceRowsList && invoiceRowsList.reportDetails?.length) && (
                                    <Text onClick={openPdfModal} className="ps-3 add-new-team">
                                        <span className="icon-22">
                                            <FilePdfOutlined />
                                        </span>
                                        Download Report
                                    </Text>
                                )}
                                <Text onClick={handleAddRow} className="ps-3 add-new-team">
                                    <span className="icon-22">
                                        <svg>
                                            <use xlinkHref="#add" />
                                        </svg>
                                    </span>
                                    Add Row
                                </Text>
                                <Text onClick={isInvoiceDataLoading ? null : showInvoiceModal} className={`ps-3 add-new-team ${isInvoiceDataLoading ? 'disabled-text' : ''}`}>
                                    <span className="icon-22">
                                        <FullscreenOutlined />
                                    </span>
                                    Expand sheet
                                </Text>
                            </>
                            :
                            invoice == 3 ?
                                <>
                                    <Text onClick={handelRatesModalOpen} className="ps-3 add-new-team">
                                        <span className="icon-22">
                                            <FullscreenOutlined />
                                        </span>
                                        Rates
                                    </Text>
                                    <Text onClick={handleAddRechargeRow} className="ps-3 add-new-team">
                                        <span className="icon-22">
                                            <svg>
                                                <use xlinkHref="#add" />
                                            </svg>
                                        </span>
                                        Create New
                                    </Text>
                                    <Text
                                        onClick={generateRechargeExcel}
                                        className="ps-3 add-new-team"
                                    >
                                        <span className="icon-22">
                                            <FileExcelOutlined />
                                        </span>
                                        Generate Report
                                    </Text>
                                    {rechargeExcelUrl && <a href={rechargeExcelUrl} target="_blank" rel="noopener noreferrer" className="ps-3 add-new-team">
                                        <span className="icon-22">
                                            <FileExcelOutlined />
                                        </span>
                                        Download Report
                                    </a>}
                                    {/* <Text onClick={handleDownloadExcel} className="ps-3 add-new-team">
                                        <span className="icon-22">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 1024 1024"><path fill="currentColor" d="M880 912H144c-17.7 0-32-14.3-32-32V144c0-17.7 14.3-32 32-32h360c4.4 0 8 3.6 8 8v56c0 4.4-3.6 8-8 8H184v656h656V520c0-4.4 3.6-8 8-8h56c4.4 0 8 3.6 8 8v360c0 17.7-14.3 32-32 32M770.87 199.131l-52.2-52.2c-4.7-4.7-1.9-12.8 4.7-13.6l179.4-21c5.1-.6 9.5 3.7 8.9 8.9l-21 179.4c-.8 6.6-8.9 9.4-13.6 4.7l-52.4-52.4l-256.2 256.2c-3.1 3.1-8.2 3.1-11.3 0l-42.4-42.4c-3.1-3.1-3.1-8.2 0-11.3z" /></svg>
                                        </span>
                                        Export to Excel
                                    </Text> */}
                                    <Text onClick={showRechargeModal} className="ps-3 add-new-team">
                                        <span className="icon-22">
                                            <FullscreenOutlined />
                                        </span>
                                        Expand sheet
                                    </Text>
                                </>
                                :
                                <>
                                    <Text onClick={() => setIsColumnsModalVisible(true)} className="ps-3 add-new-team">
                                        <span className="icon-22">
                                            <SettingOutlined />
                                        </span>
                                        Settings
                                    </Text>
                                    <Text className="ps-3 add-new-team">
                                        <span className="icon-22">
                                            <ReloadOutlined />
                                        </span>
                                        Reset
                                    </Text>
                                    <Text onClick={updateBillingData} className="ps-3 add-new-team">
                                        <span className="icon-22">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" width="24px" height="24px">
                                                <path d="M19 21H5c-1.1 0-2-.9-2-2V5c0-1.1.9-2 2-2h11l5 5v11c0 1.1-.9 2-2 2zm-7-4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm3-11H6v2h9V6z" />
                                            </svg>
                                        </span>
                                        Save
                                    </Text>
                                    <Text
                                        onClick={generateBillingPdf}
                                        className="ps-3 add-new-team"
                                    >
                                        <span className="icon-22">
                                            <FilePdfOutlined />
                                        </span>
                                        Generate Report
                                    </Text>
                                    {/* {billingPdfUrl && <a href={billingPdfUrl} target="_blank" rel="noopener noreferrer" className="ps-3 add-new-team">
                                        <span className="icon-22">
                                            <FilePdfOutlined />
                                        </span>
                                        Download Report
                                    </a>} */}
                                    {billingPdfUrl && (
                                        <Text onClick={openPdfModal} className="ps-3 add-new-team">
                                            <span className="icon-22">
                                                <FilePdfOutlined />
                                            </span>
                                            Download Report
                                        </Text>
                                    )}
                                    <Text onClick={showBillingModal} className="ps-3 add-new-team">
                                        <span className="icon-22">
                                            <FullscreenOutlined />
                                        </span>
                                        Expand
                                    </Text>
                                </>
                        }
                    </div>
                </div>
                <div className="custom-line" />
            </div>
            <div className="h-100 d-flex mt-2">
                <div className='billing_header'>
                    <Row className='billingandInvoice_body'>
                        <Col span={2} className='billing_column'>
                            <div className='billing_body'>
                                <Sider
                                    breakpoint="lg"
                                    collapsedWidth="0"
                                >
                                    <Menu
                                        theme="dark"
                                        mode="inline"
                                        items={menuData && menuData
                                            .filter((value, index, self) =>
                                                index === self.findIndex((t) => (
                                                    t.name === value.name && t.year === value.year
                                                ))
                                            ).sort((a, b) => {
                                            if (a.year == b.year) {
                                                return monthsWithIndex[a.name] - monthsWithIndex[b.name];
                                            }
                                            return a.year - b.year;
                                            }).reverse().map((item) => ({
                                            key: [item.name, item.year],
                                            label: item.name.slice(0, 3) + "-" + item.year.toString().slice(2)
                                        }))}
                                        onClick={handleInterval}
                                        defaultSelectedKeys={clickedInterval}
                                        selectedKeys={clickedInterval}

                                    >

                                    </Menu>
                                </Sider>

                                <div>
                                    <Button
                                        key="next"
                                        size="medium"
                                        shape="round"
                                        type="primary"
                                        className='add-month-button'
                                        onClick={() => { setAddMonthModalVisible(true); setSelectedYear(goingYear) }}
                                    >
                                        {truncateTableData("Add Month")}
                                    </Button>
                                </div>

                            </div>
                        </Col>
                        <Col span={22} style={{ paddingLeft: '2px' }}>
                            {invoice == 1 && <Invoice
                                selectedProject={selectedProject}
                                monthName={monthName}
                                year={year}
                                invoiceTitle={invoiceTitle}
                                setInvoiceTitle={setInvoiceTitle}
                                invoiceModalVisible={invoiceModalVisible}
                                setInvoiceModalVisible={setInvoiceModalVisible}
                                timesheetData={timesheetData}
                                setTimesheetData={setTimesheetData}
                                invoiceRowsList={invoiceRowsList}
                                isInvoiceDataLoading={isInvoiceDataLoading}
                                updateInvoiceData={updateInvoiceData}
                                handleAddRow={handleAddRow}
                            />}
                            {invoice == 2 && <MonthlyRecordGrid
                                monthName={monthName}
                                selectedProject={selectedProject}
                                year={year}
                                billingTitle={billingTitle}
                                setBillingTitle={setBillingTitle}
                                billingModalVisible={billingModalVisible}
                                setBillingModalVisible={setBillingModalVisible}
                                initialData={initialData}
                                data={data}
                                setData={setData}
                                isBillingDataLoading={isBillingDataLoading}
                                isInitialBillingDataLoading={isInitialBillingDataLoading}
                                selectedPipeline={selectedPipeline}
                                setSelectedPipeline={setSelectedPipeline}
                                pipelines={pipelines}
                                updateBillingData={updateBillingData}
                                billingColumnList={billingColumnList}
                                visibleColumns={selectedColumns}
                                allBillingColumns={visibleColumn}
                                setFilteredKeys={setFilteredKeys}
                                billingPipelines={billingPipelines}
                            />}

                            {invoice == 3 && <RechargeSheet
                                selectedMonthAndYear={selectedMonthAndYear}
                                selectedProject={selectedProject}
                                //
                                isRechargeModalVisible={isRechargeModalVisible}
                                setIsRechargeModalVisible={setIsRechargeModalVisible}
                                RechargeTitle={RechargeTitle}
                                setRechargeTitle={setRechargeTitle}
                                rechargeModalVisible={rechargeModalVisible}
                                setRechargeModalVisible={setRechargeModalVisible}
                                rechargeData={rechargeData}
                                setRechargeData={setRechargeData}
                                ratesModalVisible={ratesModalVisible}
                                setRatesModalVisible={setRatesModalVisible}
                                // rechargeExcelUrl={rechargeExcelUrl}
                                setRechargeExcelUrl={setRechargeExcelUrl}
                            />}
                        </Col>
                    </Row>
                </div>
            </div>
            <Modal
                width="35vw"
                title="Add Month & Year for Invoice and Billing"
                className="pipeline-modal"
                centered
                visible={addMonthModalVisible}
                footer={[
                    <Button key="cancel" onClick={handleMonthModalCancel}>
                        Cancel
                    </Button>,
                    <Button key="add" onClick={AddMonth} type="primary" disabled={!selectedMonth || !selectedYear}>
                        Add
                    </Button>

                ]}
            >
                <Form
                    form={editColumnForm}
                    name="basic"
                    layout="vertical"
                    autoComplete="off"
                >
                    <Row gutter={16}>
                        <Col lg={12}>
                            <Form.Item
                                label="Month Name"
                                name="monthName"
                                required
                            >
                                <Select
                                    className='client-select'
                                    placeholder='Select Month Name'
                                    showSearch
                                    allowClear
                                    optionFilterProp="children"
                                    onChange={(value) => setSelectedMonth(value)}
                                    filterOption={(input, option) =>
                                        (option.children).toLowerCase().includes(input.toLowerCase())
                                    }
                                >
                                    {monthList?.map((item, i) =>
                                        <Option key={i} value={item.name}>{item.name}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col lg={12}>
                            <Form.Item
                                label="Year"
                                name="yearName"
                                required
                            >
                                <Select
                                    className='client-select'
                                    placeholder='Select Year'
                                    showSearch
                                    allowClear
                                    optionFilterProp="children"
                                    onChange={(value) => setSelectedYear(value)}
                                    filterOption={(input, option) =>
                                        (option.children).toLowerCase().includes(input.toLowerCase())
                                    }
                                    defaultValue={year}
                                >
                                    {years?.map((item, i) =>
                                        <Option key={i} value={item}>{item}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
            <Modal
                width='100%'
                className="heatprofile-modal"
                title={`${monthName} - Month Reports`}
                centered
                visible={pdfModalVisible}
                footer={[
                    <Button key="submit1" type="primary" onClick={pdfModalCancel}>
                        Cancel
                    </Button>,
                ]}
            >
                <Table className="h-100 pipeline-table " pagination={false} columns={columns} dataSource={invoice == 1 ? docuData : docsBillingData} scroll={{ y: "100%" }}
                    loading={{
                        indicator: <div><Spin /></div>,
                        spinning: invoice == 1 ? isInvoiceDataLoading : isBillingDataLoading
                    }}
                />
            </Modal>
            <Modal
                className="pipeline-modal"
                title='Request for signature'
                centered
                visible={emailModalVisible}
                footer={[
                    <Button key="cancel" onClick={handleCancelEmailModal}>
                        {'Cancel'}
                    </Button>,
                    <Button key="apply" type="primary" onClick={handleEmailForRequest} loading={isInvoiceDataLoading}>
                        Send
                    </Button>
                ]}
            >
                <Input placeholder='Enter email' value={toEmail} onChange={(e) => setToEmail(e.target.value)} />
            </Modal>
            <Drawer
                className="pipeline-modal"
                title='Show/Hide Columns'
                centered
                visible={isColumnsModalVisible}
                onClose={() => setIsColumnsModalVisible(false)}
            >
                <div className="column-list" style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                    {billingColumnList.map(column => (
                        <Checkbox
                            key={column.columnId}
                            checked={selectedColumns.includes(column.columnId)}
                            onChange={() => handleCheckboxChange(column.columnId)}
                            className='billing-checkbox'
                        >
                            {column.name}
                        </Checkbox>
                    ))}
                </div>
            </Drawer>
        </div>
    )
}

const mapStateToProps = ({ billingDashBoardReducer, sow }) => {
    const invoiceRowsList = get(billingDashBoardReducer, 'invoiceDashBoardList', []);
    const billingDashboardList = get(billingDashBoardReducer, 'billingDashBoardList', []);
    const initialBillingDashboardList = get(billingDashBoardReducer, 'initialBillingDashBoardList', []);
    const invoiceMonthsList = get(billingDashBoardReducer, 'invoiceMonthList', []);
    const billingMonthsList = get(billingDashBoardReducer, 'billingMonthList', []);
    const rechargeMonthsList = get(billingDashBoardReducer, 'rechargeMonthList', []);
    const sowDetails = get(sow, 'sowData', []);
    const isMonthsListLoading = get(billingDashBoardReducer, 'isMonthListLoading', false);
    const isInvoiceDataLoading = get(billingDashBoardReducer, 'isInvoicesDataLoading', false);
    const isBillingDataLoading = get(billingDashBoardReducer, 'isBillingDataLoading', false);
    const isInitialBillingDataLoading = get(billingDashBoardReducer, 'isInitialBillingDataLoading', false);
    return {
        invoiceRowsList,
        billingDashboardList,
        initialBillingDashboardList,
        invoiceMonthsList,
        billingMonthsList,
        rechargeMonthsList,
        sowDetails,
        isMonthsListLoading,
        isInvoiceDataLoading,
        isBillingDataLoading,
        isInitialBillingDataLoading
    }
}

const mapDispatchToProps = {
    onFetchInvoiceDashBoardList: fetchInvoiceDashBoardList,
    onFetchBillingDashBoardList: fetchBillingDashBoardList,
    onFetchInitialBillingDashBoardList: fetchInitialBillingDashBoardList,
    onUpdateDashboardInvoice: updateDashboardInvoice,
    onUpdateDashboardBilling: updateDashboardBilling,
    onFetchBillingDashBoardPdfUrl: fetchBillingDashBoardPdfUrl,
    onFetchInvoiceDashBoardPdfUrl: fetchInvoiceDashBoardPdfUrl,
    onSendEmailForSignatureInvoice: sendEmailForSignatureInvoice,
    onFetchBillingMonthList: fetchBillingMonthList,
    onFetchInvoiceMonthList: fetchInvoiceMonthList,
    onFetchRechargeMonthList: fetchRechargeMonthList,
    onGenerateExcelForRecharge: generateExcelForRecharge,
}

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceAndBilling);